<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-text-field
        @click:append-outer="refresh()"
        @click:clear="clearSearch()"
        @keydown.enter="refresh()"
        clearable
        dense
        hide-details
        outlined
        required
        :label="$t('label.search')"
        v-model="search"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-autocomplete
        :items="groups.edges"
        :label="$tc('label.group', 2)"
        :loading="$apollo.queries.groups.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.name"
        item-value="node.pk"
        multiple
        outlined
        v-model="selectedGroupPks"
      >
        <template v-slot:selection="data">
          <v-chip v-if="selectedGroupPks.length < 2" v-bind="data.attrs" small>
            {{ data.item.node.name }}
          </v-chip>
          <div v-else-if="data.index === 0">
            {{ `${selectedGroupPks.length} items` }}
          </div>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" sm="6" v-if="me.isSuperuser || me.isPartnerUser">
      <v-autocomplete
        :items="tenants.edges"
        :label="$tc('label.tenant', 1)"
        :loading="$apollo.queries.tenants.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.tenantName"
        item-value="node.id"
        outlined
        v-model="selectedTenant"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" sm="6" v-if="me.isSuperuser || me.isPartnerUser">
      <v-autocomplete
        :items="allPartners.edges"
        :label="$tc('label.partner', 1)"
        :loading="$apollo.queries.allPartners.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.name"
        item-value="node.id"
        outlined
        v-model="selectedPartner"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-switch
        :label="$tc('label.group', 2)"
        class="mb-0 mt-2 py-0"
        hide-details
        v-model="showGroups"
      ></v-switch>
    </v-col>
    <v-spacer />
    <v-col align="right" cols="12" md="2" sm="6">
      <v-btn @click="resetFilters()" color="primary" dark rounded small text>
        {{ $t("label.clearFilters") }}
      </v-btn>
    </v-col>
    <v-col cols="12" md="12">
      <v-data-table
        :footer-props="{
          'disable-pagination': $apollo.queries.users.loading,
          'items-per-page-options': [10, 25, 50, 75, 100],
        }"
        :headers="headers"
        :items="users.edges"
        :loading="$apollo.queries.users.loading"
        :options.sync="dataTableOptions"
        :server-items-length="users.totalCount"
        :show-select="
          hasPermission('users.delete_customuser') ? 'show-select' : undefined
        "
        item-key="node.id"
        v-model="selectedItems"
      >
        <template v-slot:[`item.node.identityProvider`]="{ item }">
          <span>
            {{
              item.node.identityProvider == "TAPTARGET"
                ? "TapTarget"
                : item.node.identityProvider == "MICROSOFT_AZURE_AD"
                ? "Microsoft"
                : item.node.identityProvider
            }}
          </span>
        </template>
        <template v-slot:[`item.node.isSuperuser`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                :color="item.node.isSuperuser ? 'success' : 'error'"
                v-on="on"
                >{{ item.node.isSuperuser ? "check_circle" : "cancel" }}</v-icon
              >
            </template>
            <span>
              {{ item.node.isSuperuser }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.node.require2fa`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                :color="
                  item.node.identityProvider != 'TAPTARGET'
                    ? ''
                    : item.node.require2fa &&
                      item.node.identityProvider == 'TAPTARGET'
                    ? 'success'
                    : 'error'
                "
                v-on="on"
                >{{
                  item.node.identityProvider != "TAPTARGET"
                    ? ""
                    : item.node.require2fa &&
                      item.node.identityProvider == "TAPTARGET"
                    ? "check_circle"
                    : "cancel"
                }}</v-icon
              >
            </template>
            <span>
              {{ item.node.require2fa }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.node.enabled2fa`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                :color="
                  item.node.identityProvider != 'TAPTARGET'
                    ? ''
                    : item.node.enabled2fa &&
                      item.node.identityProvider == 'TAPTARGET'
                    ? 'success'
                    : 'error'
                "
                v-on="on"
                >{{
                  item.node.identityProvider != "TAPTARGET"
                    ? ""
                    : item.node.enabled2fa &&
                      item.node.identityProvider == "TAPTARGET"
                    ? "check_circle"
                    : "cancel"
                }}</v-icon
              >
            </template>
            <span>
              {{ item.node.enabled2fa }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.node.groups`]="{ item }">
          <div>
            <v-chip
              v-for="edge in item.node.groups.edges"
              :key="edge.node.id"
              small
              class="ma-1"
            >
              {{ edge.node.name }}
            </v-chip>
          </div>
        </template>
        <template
          v-if="hasPermission('users.change_customuser')"
          v-slot:[`item.action`]="{ item }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="
                  isLoggedInAsOtherUser ||
                  (me?.partner?.type == 'SOFTWARE' && !item.node.isPartnerUser)
                "
                :loading="isLoggingInAsOtherUser"
                @click="loginAsOtherUser(item.node)"
                color="primary"
                icon
                v-on="on"
              >
                <v-icon>mouse</v-icon>
              </v-btn>
            </template>
            <span> {{ $t("loginAsOtherUser") }} </span>
          </v-tooltip>
          <v-btn @click="edit(item.node)" color="primary" icon>
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />
    <UserEditDialog
      :dialog.sync="editDialog"
      :object.sync="editedItem"
      v-on:changed="onChanged"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import UserEditDialog from "@/components/users/UserEditDialog.vue";

export default {
  name: "users-list",
  components: {
    DeleteDialog,
    UserEditDialog,
  },

  apollo: {
    allPartners: {
      query: gql`
        query allPartners($id: ID) {
          allPartners(id: $id, orderBy: ["name"]) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        if (this.me.isPartnerUser) {
          return {
            id: this.me.partner.id,
          };
        }
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allPartners,
      skip: true,
      pollInterval: 60000,
    },

    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip: true,
      pollInterval: 60000,
    },

    groups: {
      query: gql`
        query groups {
          groups(orderBy: ["name"]) {
            edges {
              node {
                id
                name
                pk
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.groups,
    },

    users: {
      query: gql`
        query users(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $orderBy: [String]
          $search: String
          $partner: ID
          $tenant: ID
          $groupPks: [ID]
        ) {
          users(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: $orderBy
            search: $search
            partner: $partner
            tenant: $tenant
            groupPks: $groupPks
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                enabled2fa
                email
                firstName
                groups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                id
                identityProvider
                isPartnerUser
                isSuperuser
                lastName
                partner {
                  id
                  name
                }
                require2fa
                tenant {
                  id
                  tenantName
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.users,
      skip: true,
      pollInterval: 60000,
    },
  },

  data: function () {
    return {
      allPartners: {},
      users: {},
      deleteDialog: false,
      editDialog: false,
      editedItem: {},
      error: null,
      groups: {},
      headersUser: [
        {
          text: this.$t("label.email"),
          value: "node.email",
          sortable: true,
        },
        {
          text: this.$t("label.firstName"),
          value: "node.firstName",
          sortable: true,
        },
        {
          text: this.$t("label.lastName"),
          value: "node.lastName",
          sortable: true,
        },
        {
          text: this.$t("label.identityProvider"),
          value: "node.identityProvider",
          sortable: true,
        },
        {
          text: this.$t("label.require2fa"),
          value: "node.require2fa",
          sortable: false,
        },
        {
          text: this.$t("label.enabled2fa"),
          value: "node.enabled2fa",
          sortable: false,
        },
      ],
      headersSuperuser: [
        {
          text: this.$tc("label.tenant", 1),
          value: "node.tenant.tenantName",
          sortable: true,
        },
        {
          text: this.$tc("label.partner", 1),
          value: "node.partner.name",
          sortable: true,
        },
        {
          text: this.$t("label.email"),
          value: "node.email",
          sortable: true,
        },
        {
          text: this.$t("label.firstName"),
          value: "node.firstName",
          sortable: true,
        },
        {
          text: this.$t("label.lastName"),
          value: "node.lastName",
          sortable: true,
        },
        {
          text: this.$t("label.identityProvider"),
          value: "node.identityProvider",
          sortable: true,
        },
        {
          text: "Superuser",
          value: "node.isSuperuser",
          sortable: true,
        },
        {
          text: this.$t("label.require2fa"),
          value: "node.require2fa",
          sortable: false,
        },
        {
          text: this.$t("label.enabled2fa"),
          value: "node.enabled2fa",
          sortable: false,
        },
      ],
      isLoggingInAsOtherUser: false,
      selectedItems: [],
      tenants: {},
      showGroups: false,
    };
  },

  computed: {
    headers() {
      // Start with the base headers
      let headers =
        this.me.isSuperuser || this.me.isPartnerUser
          ? [...this.headersSuperuser]
          : [...this.headersUser];

      // Conditionally add 'Groups' column if groups are selected
      if (this.showGroups) {
        headers.push({
          text: this.$tc("label.group", 2),
          value: "node.groups",
          sortable: false,
        });
      }

      // Add action as last column
      headers.push({
        text: this.$t("label.action"),
        value: "action",
        width: "120px",
        align: "center",
        sortable: false,
      });

      return headers;
    },

    dataTableOptions: {
      get() {
        return this.$store.getters["usersList/getDataTableOptions"];
      },
      set(value) {
        this.$store.dispatch("usersList/setDataTableOptions", value);
      },
    },

    isLoggedInAsOtherUser() {
      return this.$store.state.user.loggedInAsOtherUser;
    },

    me() {
      return this.$store.state.user.me;
    },

    search: {
      get() {
        return this.$store.getters["usersList/getSearch"];
      },
      set(value) {
        this.$store.dispatch("usersList/setSearch", value);
      },
    },

    selectedGroupPks: {
      get() {
        return this.$store.getters["usersList/getSelectedGroupPks"];
      },
      set(value) {
        this.$store.dispatch("usersList/setSelectedGroupPks", value);
      },
    },

    selectedPartner: {
      get() {
        return this.$store.getters["usersList/getSelectedPartner"];
      },
      set(value) {
        this.$store.dispatch("usersList/setSelectedPartner", value);
      },
    },

    selectedTenant: {
      get() {
        return this.$store.getters["usersList/getSelectedTenant"];
      },
      set(value) {
        this.$store.dispatch("usersList/setSelectedTenant", value);
      },
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    selectedItems() {
      this.$emit("updateNrOfSelectedItems", this.selectedItems.length);
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;

    if (this.me.isSuperuser || this.me.isPartnerUser) {
      this.$apollo.queries.allPartners.skip = false;
      this.$apollo.queries.tenants.skip = false;
    }
  },

  mounted() {},

  methods: {
    add() {
      this.editedItem = null;
      this.editDialog = true;
    },

    clearSearch() {
      this.search = null;
      this.refresh();
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    edit(item) {
      this.editedItem = item;
      this.editDialog = true;
    },

    loginAsOtherUser(item) {
      this.isLoggingInAsOtherUser = true;

      const payload = { userId: item.id };

      this.$store
        .dispatch("user/loginAsOtherUser", payload)
        .then(() => {
          this.$router.push(this.$route.query.redirect || "/");
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              // show snackbar
              const payload = {
                color: "error",
                message: err.message,
              };
              this.$store.dispatch("snackbar/showMessage", payload);
            }
          }
        })
        .finally(() => {
          this.isLoggingInAsOtherUser = false;
        });
    },

    onChanged() {
      this.$apollo.queries.users.refresh();
    },

    onDeleteConfirmed() {
      var ids = [];
      this.selectedItems.forEach(function (item, index) {
        ids.push(item.node.id);
      });

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteUsers($input: DeleteUsersInput!) {
              deleteUsers(input: $input) {
                users {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.users.refresh();

          this.deleteDialog = false;
          this.selectedItems = [];

          const payload = {
            color: "success",
            message: `Users successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error;
        });
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.users.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.users.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      let variables = {
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        partner: this.selectedPartner,
        tenant: this.selectedTenant,
      };

      if (this.selectedGroupPks) {
        variables.groupPks = this.selectedGroupPks;
      }

      this.$apollo.queries.users.setVariables(variables);

      this.$apollo.queries.users.skip = false;
      this.$apollo.queries.users.refresh();

      this.page = page;
    },

    resetFilters() {
      this.selectedPartner = null;
      this.selectedTenant = null;
      this.clearSearch();
    },
  },
};
</script>

<i18n>
{
  "en": {
	"loginAsOtherUser": "Login as other user"
  },
  "nl": {
	"loginAsOtherUser": "Inloggen als andere gebruiker"
  },
  "de": {
	"loginAsOtherUser": "Als anderer Benutzer anmelden"
  }
}
</i18n>
